<template>
    <div class="video_box" :style="height ? `height:${height}rem` : ''" v-if="url">
        <div class="container" :style="height ? `height:${height}rem` : ''">
            <div class="player" :style="height ? `height:${height}rem` : ''" @contextmenu.prevent="rightClcik()">
                <video-player
                        class="video-player vjs-custom-skin video-swiper"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions"
                        @play="onPlayerPlay($event)"
                        @pause="onPlayerPause($event)"
                ></video-player>
                
            </div>
        </div>
    </div>
</template>

<script>
    import { videoPlayer } from "vue-video-player";
    import { mapMutations } from "vuex";
    export default {
        props: ["url",'cover','time','height'],
        data() {
            return {
                videoUrl:
                    "http://exshow.oss-cn-shanghai.aliyuncs.com/exshow/imgs/201902/f87d763f83af49d69d90cd0561fc02f2.jpg",
                playerOptions: {
                    autoplay: false,
                    muted: false,
                    loop: false,
                    preload: "auto",
                    language: "zh-CN",
                    aspectRatio: "16:9",
                    fluid: true,
                    sources: [
                        {
                            type: "video/mp4",
                            src: this.url
                        }
                    ],
                    poster: this.videoUrl, //你的封面地址
                    width: document.documentElement.clientWidth,
                    notSupportedMessage: "此视频暂无法播放，请稍后再试",
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true //全屏按钮
                    }
                },
                state: "",
                paused: "",
                running: "",
                autoplay:0,
            };
        },
        created() {
            if (this.url) {
                if(this.cover){
                    this.videoUrl = this.cover;
                } else if(this.time){
                    this.videoUrl = `${this.url}?x-oss-process=video/snapshot,t_${this.time},f_jpg,w_590,h_332,m_fast`;
                }
                this.playerOptions = {
                    autoplay: false,
                    muted: false,
                    loop: false,
                    preload: "auto",
                    language: "zh-CN",
                    aspectRatio: "16:9",
                    fluid: true,
                    sources: [
                        {
                            type: "video/mp4",
                            src: this.url
                        }
                    ],
                    poster: this.videoUrl, //你的封面地址
                    width: document.documentElement.clientWidth,
                    notSupportedMessage: "此视频暂无法播放，请稍后再试",
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true //全屏按钮
                    }
                };
                const that = this;
            }
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player;
            }
        },
        components: {
            videoPlayer
        },
        methods: {
            onPlayerPlay(e){
                this.$emit('onPlayerPlay', this.autoplay)
                console.log(e,'视频播放');
                this.$emit('VideoPaly');
            },
            onPlayerPause(e){
                // console.log(e,'暂停播放');
                this.$emit('VideoPalyfalse');
            },
            //轮播改变暂停播放
            Pause(){
                // console.log('轮播改变暂停播放');
                this.player.pause();
            },
            rightClcik(){
                // console.log('右键了')
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";

    .video_box{
        margin: 0;
    }
    .container,
    .player{
        height:100%;
    }
    .video-player {
        height: 100%;
        display: flex;
        align-items: center;
    }
    ::v-deep{
        .vjs-poster{
          background-size: 100% 100%;
        }
        .vjs-big-play-button:focus{
          border:none !important;
          background-color: rgba(0,0,0,0.45) !important;
          outline: none !important;
        }
        .video-js .vjs-tech:focus{
          outline: none !important;
        }
        .video-js .vjs-big-play-button {
            width: 72px;
            height: 72px;
            border-radius: 100%;
            z-index: 100;
            color: #fff;
            border: solid 1px #979797;
        }
        .vjs-custom-skin > .video-js .vjs-big-play-button{
            margin-left: -36px;
        }
        .container {
            background-color: #f5f5f5;
            min-height: 100%;
            margin: 0;
        }
    }

</style>
