<template>
  <div class="rich-content">
    <div v-html="info"></div>
  </div>
</template>

<script>
export default {
  name: 'rich-content',
  props: {
    info: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  created () {
  },
}
</script>

<style lang="scss" scoped>
.rich-content {
  ::v-deep img {
    display: block;
    max-width: 95%;
    margin: auto;
  }
}
</style>