<template>
  <div class="m-card3">
    <div class="mc-top">
      <div class="mc-top-title">
        <span class="mc-top-txt s-bold s-primary">热点资讯</span>
      </div>
    </div>
    <div class="news-list list-box">
      <div v-if="NewList.length">
        <div :key="index" @click="goToDetails(item)" class="list-one" v-for="(item,index) in NewList">
          <img :src="item.covers[0]" v-if="item.covers[0]" v-load="'oNews'" />
          <img src="@/assets/img/empty.png" v-else />
          <p :title="item.title">{{item.title}}</p>
        </div>
      </div>
      <!-- <div
        class="no-msg"
        v-else
      >暂无数据！</div> -->
      <div class="no-msg" v-if="loading">数据加载中...</div>
      <div class="no-msg" v-else-if="!loading && !NewList.length">暂无数据！</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "news-hot",
  data () {
    return {
      NewList: [],
      page: 1,
      size: 20,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: 'getExhibitionId',
    }),
  },
  created () {
    this.init();
  },
  methods: {
    ...mapActions({
      getNewsList: 'news/getNewsList',
      linkToNewsDetails: 'linkTo/linkToNewsDetails',
      graphqlPost: 'graphqlPostByZXS',
    }),
    init () {
      this.getNews();
    },
    async getNews () {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          articleQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  commentCount
                  content
                  covers
                  createAt
                  id
                  externalUrl
                  introduction
                  publishAt
                  title
                  updateAt
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "itemId",
          v: this.exhibitionId,
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
        NotEqual: {
          n: 'topping',
          v: 0
        },
      };
      let order = [
        { N: "topic", v: 0 },
        { N: "toppingIndex", v: 0 },
        { N: "publishAt", v: 0 },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.page,
            size: this.size,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        }
      };
      let data = await this.graphqlPost(opt);
      if (data.data.articleQuery.query) {
        let res = JSON.parse(JSON.stringify(data.data.articleQuery.query));
        res.items.map(c => {
          if (c.covers.search(/^\[/) !== -1) {
            c.covers = JSON.parse(c.covers);
          } else {
            c.covers = [c.covers];
          }
        })
        this.NewList = res.items;
        this.ToTal = res.totalCount;
      }
      this.loading = false;
    },
    goToDetails (item) {
      if (item.externalUrl) {
        window.open(item.externalUrl);
      } else {
        this.linkToNewsDetails(item.id);
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.list-box {
  background: #fff;
  padding: 0 20px 20px 20px;
  width: 100%;
  .list-one {
    margin-top: 20px;
    cursor: pointer;
    img {
      width: 100%;
      height: 180px;
      display: block;
    }
    p {
      width: 99%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 18px;
      color: #333;
      margin-top: 10px;
    }
    span {
      font-size: 12px;
      color: #666;
    }
  }
  .list-one:last-child {
    border: none;
  }
  .list-one:hover {
    p {
      @include font_color(#0190fe);
    }
  }
}
.no-msg {
  font-size: 14px;
  color: #666;
  text-align: center;
  padding: 50px 0;
}
.load-more {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #666;
  padding: 20px 0;
  cursor: pointer;
}
</style>